@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");
.body {
  font-family: "Poppins", "sans-serif";
  background-image: url("../src/pages/background-jo.png");
  background-color: #f3f3f3;
}

.logo {
  background-image: url("../src/pages/fond.jpg");
}

.fond,
.square {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  background: var(--unnamed-color-00004d) 0% 0% no-repeat padding-box;
  opacity: 0.6;
}

.voile {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  border-radius: 54px;
}

.foot {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  border-radius: 54px;
  object-fit: cover;
}

.square {
  background: #00004d 0% 0% no-repeat padding-box;
  opacity: 0.8;
  display: flex;
  max-width: 100%;
}

.presentation {
  position: absolute;
  margin-top: 13%;
  margin-left: 8%;
  margin-bottom: 15%;
  width: 455px;
  height: 340px;
  border: 1px solid var(--unnamed-color-707070);
  background: rgba(255, 255, 255, 0.062745098) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(112, 112, 112, 0.062745098);
  border-radius: 45px;
}

.pres-title,
.pres-description {
  color: white;
  text-align: left;
  margin: 25px;
}

.pres-title {
  font-weight: 700;
  letter-spacing: 2px;
  text-shadow: 2px 2px 3px #00004d;
}

.pres-description {
  font-weight: 300;
}

span {
  font-weight: 700;
}

.choose-title {
  color: #00004d;
  margin-left: 5%;
}

.cards {
  display: flex;
  justify-content: space-evenly;
}

.card-foot,
.card-voile {
  background-color: #ed354f;
  height: 270px;
  width: 270px;
  color: white;
  text-align: center;
  border-radius: 54px;
  background-size: contain;
  margin-bottom: 60px;
}

.card-foot {
  background-image: url("../src/pages/foot.png");
}

.card-voile {
  background-image: url("../src/pages/voilecrop.png");
}

.card-foot > p,
.card-voile > p {
  z-index: 1000;
  margin-top: 90px;
  font-size: 20px;
}

.card-foot:hover,
.card-voile:hover {
  cursor: pointer;
  color: #00004d;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  padding: 0 2rem;
}

.footer {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #707070;
  padding-bottom: 40px;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.row {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
  padding: 10px;
}

.column img {
  height: 70px;
  object-fit: contain;
}

.smaller {
  height: 40px !important;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
body {
  background-image: url("");
  background-repeat: no-repeat;
}

.map {
  width: 100%;
}

.container-news {
  background-color: white;
  border-radius: 37px;
  padding: 20px;
  margin-right: 45px;
  margin-left: 45px;
}

.news-description,
.news-title {
  color: #00004d;
}

.news-description {
  margin-top: -15px;
  margin-bottom: 45px;
}

a:-webkit-any-link {
  color: #00004d;
  text-decoration: none;
}

a:-webkit-any-link:hover {
  color: #ed354f;
}

.footer {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #707070;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
}

.footer a {
  flex-basis: 30%;
  text-align: center;
  padding: 10px;
}

.footer img {
  height: 70px;
  object-fit: contain;
}

.smaller {
  height: 40px !important;
}

.url1 {
  background-image: url("../src/pages/background-jo.png");
}

.title-voile,
.description-voile {
  text-align: left;
}

.agenda-container {
  color: #00004d;
  padding: 25px;
}

.nb-athletes {
  font-weight: bold;
  font-size: 20px;
}

.items,
.itemst {
  width: 50%;
}

