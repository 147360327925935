@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

$font: "Poppins", "sans-serif";
$fontLight: 300;
$fontRegular: 500;
$fontBold: 700;

.body {
  font-family: $font;
  background-image: url("../src/pages/background-jo.png");
  background-color: #f3f3f3;
}

.logo {
  background-image: url("../src/pages/fond.jpg");
}

.fond,
.square {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  background: var(--unnamed-color-00004d) 0% 0% no-repeat padding-box;
  opacity: 0.6;
}

.voile {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  border-radius: 54px;
}

.foot {
  width: 100%;
  height: 100%;
  opacity: 0.6;
  border-radius: 54px;
  object-fit: cover;
}

.square {
  background: #00004d 0% 0% no-repeat padding-box;
  opacity: 0.8;
  display: flex;
  max-width: 100%;
}

.presentation {
  position: absolute;
  margin-top: 13%;
  margin-left: 8%;
  margin-bottom: 15%;
  width: 455px;
  height: 340px;
  border: 1px solid var(--unnamed-color-707070);
  background: #ffffff10 0% 0% no-repeat padding-box;
  border: 1px solid #70707010;
  border-radius: 45px;
}

.pres-title,
.pres-description {
  color: white;
  text-align: left;
  margin: 25px;
}

.pres-title {
  font-weight: $fontBold;
  letter-spacing: 2px;
  text-shadow: 2px 2px 3px #00004d;
}

.pres-description {
  font-weight: $fontLight;
}

span {
  font-weight: $fontBold;
}

.choose-title {
  color: #00004d;
  $font: $fontBold;
  margin-left: 5%;
}

.cards {
  display: flex;
  justify-content: space-evenly;
}

.card-foot,
.card-voile {
  background-color: #ed354f;
  height: 270px;
  width: 270px;
  color: white;
  text-align: center;
  border-radius: 54px;
  background-size: contain;
  margin-bottom: 60px;
}

.card-foot {
  background-image: url("../src/pages/foot.png");
}

.card-voile {
  background-image: url("../src/pages/voilecrop.png");
}

.card-foot > p,
.card-voile > p {
  z-index: 1000;
  margin-top: 90px;
  font-size: 20px;
}

.card-foot:hover,
.card-voile:hover {
  cursor: pointer;
  color: #00004d;
}
