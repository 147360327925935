.title-voile,
.description-voile {
  text-align: left;
}

.agenda-container {
  color: #00004d;
  padding: 25px;
}

.nb-athletes {
  font-weight: bold;
  font-size: 20px;
}

.items,
.itemst {
  width: 50%;
}
