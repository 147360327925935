.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  padding: 0 2rem;
}
.footer {
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #707070;
  padding-bottom: 40px;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.row {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
  padding: 10px;
}

.column img {
  height: 70px;
  object-fit: contain;
}

.smaller {
  height: 40px !important;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

body {
  background-image: url("");
  background-repeat: no-repeat;
}

.map {
  width: 100%;
}
